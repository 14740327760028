<template>
    <div class="content">
        <div class="work" v-show="$route.path == '/home/work/index'">
            <div class="work_nav">
                <div class="nav-group">
                    <div class="nav-item">
                        <router-link to="/home/work/up">
                            <div class="item bg1">
                                <div class="work_icon">
                                    <img
                                        src="@/assets/images/work/work_icon_1.png"
                                        alt=""
                                    />
                                </div>
                                <div class="work_text">
                                    <span>发布视频</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="nav-item">
                        <router-link to="/make/editing">
                            <div class="item bg2">
                                <div class="work_icon">
                                    <img
                                        src="@/assets/images/work/work_icon_2.png"
                                        alt=""
                                    />
                                </div>
                                <div class="work_text">
                                    <span>极速裂变</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="nav-item">
                        <router-link to="/home/work/remove_logo">
                            <div class="item bg3">
                                <div class="work_icon">
                                    <img
                                        src="@/assets/images/work/work_icon_3.png"
                                        alt=""
                                    />
                                </div>
                                <div class="work_text">
                                    <span>一键去水印</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="nav-item">
                        <router-link to="/home/work/Material/Material">
                            <div class="item bg4">
                                <div class="work_icon">
                                    <img
                                        src="@/assets/images/work/work_icon_4.png"
                                        alt=""
                                    />
                                </div>
                                <div class="work_text">
                                    <span>素材原料</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="nav-item">
                        <router-link to="/home/work/library/mev">
                            <div class="item bg5">
                                <div class="work_icon">
                                    <img
                                        src="@/assets/images/work/work_icon_5.png"
                                        alt=""
                                    />
                                </div>
                                <div class="work_text">
                                    <span>创意库</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="nav-item">
                        <router-link to="/home/work/turn">
                            <div class="item bg6">
                                <div class="work_icon">
                                    <img
                                        src="@/assets/images/work/work_icon_6.png"
                                        alt=""
                                    />
                                </div>
                                <div class="work_text">
                                    <span>文本转语音</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>

<style lang="less" scoped>
.content {
    height: 100%;
}
.work_nav {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    font-size: 18px;
    color: #fff;

    img {
        width: 48px;
        height: 48px;
        margin-right: 20px;
    }
    .nav-group {
        display: flex;
        overflow: auto hidden;
        .nav-item {
            flex: 0 0 230px;
            height: 116px;
            padding: 10px 14px;
        }
    }
    .item {
        padding: 30px 0;
    }

    .item {
        background-size: 100% 100%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .work_text {
            text-decoration: none;
            color: #fff;
        }
    }

    .bg1 {
        background-image: url(../../assets/images/work/bg6.png);
        box-shadow: 0px 4px 12px 0px #ae5bd8;
    }

    .bg2 {
        background-image: url(../../assets/images/work/bg2.png);
        box-shadow: 0px 4px 12px 0px #ff7373;
    }

    .bg3 {
        background-image: url(../../assets/images/work/bg3.png);
        box-shadow: 0px 4px 12px 0px #ff8e4f;
    }

    .bg4 {
        background-image: url(../../assets/images/work/bg4.png);
        box-shadow: 0px 4px 12px 0px #299bff;
    }

    .bg5 {
        background-image: url(../../assets/images/work/bg5.png);
        box-shadow: 0px 4px 12px 0px #35c490;
    }

    .bg6 {
        background-image: url(../../assets/images/work/bg6.png);
        box-shadow: 0px 4px 12px 0px #ae5bd8;
    }
}
.work-viedo-box {
    // border-radius: 10px;
    // overflow: hidden;
    margin-top: 20px;
    img {
        width: 100%;
        border-radius: 10px;
    }
}
.select {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;

    &-box {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;

        img {
            width: 95%;
            cursor: pointer;
        }
    }
}
</style>